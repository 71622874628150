import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, Container } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const Home = () => {
    return (
        <Container
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh', // Adjust the height as needed to center the card vertically
            }}
        >
            <Card sx={{ maxWidth: 600, padding: '15px' }}>
                <CardMedia
                    component="img"
                    height="auto"
                    image="/Landscape.png"
                    alt="green iguana"
                    style={{
                        padding: '15px', // Add padding around the image
                    }}
                />
                <CardContent>
                    <Typography gutterBottom variant="h3" component="div">
                        GetEasy Services
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        All your paperwork solutions in safest hands
                    </Typography>
                    <br />
                    <Typography variant="body1" color="text.secondary">
                        "Note: This website has been HIDDEN for research works" 
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                        For any queries, please leave an email:  
                    </Typography>
                    <a href="mailto:geteasy.forms@gmail.com" target="blank" style={{ textDecoration:'none', color:'white' }}>
                        <Button sx={{ mr:2 }} variant="contained" style={{backgroundColor: 'lightgray', color: '#2b6777'}}>
                            {<EmailIcon/>}  geteasy.forms@gmail.com
                        </Button>
                    </a>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Home;
